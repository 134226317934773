<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    show-expand
                    :headers="testHeaders"
                    :loading="$store.getters['testReceipt/loading']"
                    :items="$store.getters['testReceipt/testReceipts']"
                    :search="searchTestReceipt"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Delivered Report</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form @submit.prevent="() => false" class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Test Receipt"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchTestReceipt"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.doctor.display_text`]="{ item }">
                        <span v-if="item.doctor == null">N/A</span>
                        <span v-else>{{ item.doctor.display_text }}</span>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <table class="details__table">
                                <tr>
                                    <th>Test Name</th>
                                    <th>Room No.</th>
                                    <th>Completed At</th>
                                    <th>Completed Note</th>
                                    <th>Completed By</th>
                                    <th>Delivered At</th>
                                    <th>Delivered Note</th>
                                    <th>Delivered By</th>
                                </tr>
                                <tr v-for="(_item, i) in item.test_receipt_details" :key="i">
                                    <td>{{ _item.test.name }}</td>
                                    <td>{{ _item.test.room_number }}</td>
                                    <td>{{ _item.report_completed_at }}</td>
                                    <td>{{ _item.report_completed_note ? _item.report_completed_note : 'N/A' }}</td>
                                    <td>{{ _item.report_completed_by != null ? _item.report_completed_by.name : 'N/A' }}</td>
                                    <td>{{ _item.report_delivered_at }}</td>
                                    <td>{{ _item.report_delivered_note ? _item.report_delivered_note : 'N/A' }}</td>
                                    <td>{{ _item.report_delivered_by != null ? _item.report_delivered_by.name : 'N/A' }}</td>
                                </tr>
                            </table>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'TestReportDelivered',
    data: ()=> ({
        searchTestReceipt: '',
        testHeaders: [
            { text: 'Receipt ID', value: 'code' },
            { text: 'Receipt Date', value: 'bill_date' },
            { text: 'Patient ID', value: 'patient.code' },
            { text: 'Patient', value: 'patient.display_text' },
            { text: 'Referred By', value: 'doctor.display_text' },
            { text: 'Saved By', value: 'added_by.name' },
            { text: '', value: 'data-table-expand' },
        ],
    }),
    created() {
        this.$store.dispatch('testReceipt/getTestReceipts', {isDelivered: true})
    },
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>